import React, {useContext} from 'react';
import {NavLink} from "react-router-dom";
import {Checkbox} from "../../";
// import {ProjectsContext} from "../../../contexts/adm";

const List = ({projects}) => {
  // const {changeStatus} = useContext(ProjectsContext);

  return (
          <div className="table__wrapper">
            <table className='rounded-lg'>
              <thead>
              <tr>
                <th align='center'>ID</th>
                <th align='left'>Название</th>
                <th align='left'>Код</th>
                <th align='center'>Статус</th>
                <th align='right'>Действия</th>
              </tr>
              </thead>
              <tbody>
              {projects.map((project) => (
                      <tr>
                        <td align='center'>{project.id}</td>
                        <td align='left'>{project.name}</td>
                        <td align='left'>{project.code}</td>
                        <td align='center'>
                          <Checkbox
                                  checked={project.status}
                                  onChange={() => {
                                    console.log('da')
                                    // changeStatus(project.id, !project.status);
                                  }}
                                  id={`status_${project.id}`}
                          />
                        </td>
                        <td align='right'>
                          <NavLink to={project.code}>
                            Изменить
                          </NavLink>
                        </td>
                      </tr>
              ))}
              </tbody>
            </table>
          </div>
  );
};

export default List;