import React, {useContext, useState} from "react";
import classNames from "classnames";
import {XMarkIcon, UsersIcon, CheckIcon} from "@heroicons/react/24/outline";
import axios from "axios";
import {NavLink, useLocation, useNavigate} from "react-router-dom";

import {Badge} from "../";
import {API_URL, tables, actions} from "../../api";
import {AuthContext} from "../../contexts";

import "./List.scss";

const List = ({items, isRemovable, onClick, onRemove, onClickItem}) => {
  const [deletingList, setDeletingList] = useState(null);
  const {isAdmin} = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const removeList = (list) => {
    setDeletingList(null);
    axios
            .post(API_URL, {
              table: tables.lists,
              action: actions.delete,
              id: list.id,
              shared: list.shared,
            })
            .then(() => {
              if (location.pathname.split("lists/")[1] === list.id) {
                navigate("/lists/all");
              }
              onRemove(list.id);
            });
  }

  const toggleDeletingList = (id) => {
    if (deletingList === id) {
      setDeletingList(null);
    } else {
      setDeletingList(id);
    }
  }

  return (
          <ul className="todo__lists" onClick={onClick}>
            {items.map((item) => (
                    <NavLink to={item.path ?? `/lists/${item.id}`}
                             className={classNames(item.className ?? "todo__lists-item", {"relative": item.shared})}
                             onClick={onClickItem ? (() => onClickItem(item)) : null}
                             key={item.id ?? 0}
                    >
                      {item.shared && isAdmin &&
                              <i className="icon absolute w-5 h-5 -left-6 top-1/3 opacity-60">
                                <UsersIcon/>
                              </i>
                      }
                      <i className={classNames({"icon": item.icon}, "inline-flex items-center w-6 h-6")}>
                        {
                          item.icon
                                  ? item.icon
                                  : <Badge color={item.color.hash}>{item.tasks && item.tasks.length}</Badge>
                        }
                      </i>
                      <span className="todo__lists-item__text">{item.name}</span>
                      {isRemovable && (!item.shared || isAdmin) ? (
                              <>
                                <i
                                        onClick={(e) => {
                                          if (deletingList === item.id) {
                                            e.preventDefault();
                                            removeList(item);
                                          }
                                        }}
                                        className={classNames({"opacity-0": deletingList !== item.id})}
                                >
                                  <CheckIcon className={"todo__lists__remove-icon"}/>
                                </i>
                                <i onClick={(e) => {
                                  e.preventDefault();
                                  toggleDeletingList(item.id);
                                }}>
                                  <XMarkIcon className={"todo__lists__remove-icon"}/>
                                </i>
                              </>
                      ) : (
                              <i className="todo__lists__remove-icon opacity-60" title="Общий список">
                                <UsersIcon/>
                              </i>
                      )}
                    </NavLink>
            ))}
          </ul>
  );
};

export default List;
