import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';

import './index.scss';
import { ThemeProvider, AuthProvider, TodoProvider } from './contexts';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider>
        <AuthProvider>
          <TodoProvider>
            <App/>
          </TodoProvider>
        </AuthProvider>
      </ThemeProvider>
    </Router>
  </React.StrictMode>,
);
