import React, {useContext} from 'react';
import {MoonIcon, SunIcon} from "@heroicons/react/24/solid";

import {ThemeContext} from "../../contexts";

import "./ThemeSwitch.scss";

const ThemeSwitch = () => {
    const {theme, toggleTheme} = useContext(ThemeContext)

    return (
        <div className="theme-switch" onClick={() => toggleTheme()}>
            {theme && (
                theme === "light"
                    ? <SunIcon/>
                    : <MoonIcon/>
            )}
        </div>
    );
};

export default ThemeSwitch;