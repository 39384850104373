import React from 'react';
import classNames from "classnames";

import "./Badge.scss";

const Badge = ({color, className, onClick, children}) => {
    // {[`bg-[${color}] text-[lighten(${color}, 20%)]`]: color},
    return (
        <i className={classNames("badge", className)}
           style={{
               backgroundColor: color,
               color: "#ffffff50"
           }}
           onClick={onClick}
        >{children}</i>
    );
};

export default Badge;