import React, {useContext, useState} from "react";
import {Link} from "react-router-dom";
import CustomScroll from "react-custom-scroll";
import "react-custom-scroll/dist/customScroll.css";

import "./Home.scss";
import {MyButton, AuthModal} from "../";
import {AuthContext} from '../../contexts';

import logo from "../../assets/img/logo.svg";
import img1 from "../../assets/img/ScreenshotLists.png";
import img2 from "../../assets/img/ScreenshotTasks.png";
import img3 from "../../assets/img/ScreenshotAllTasks.png";
import img4 from "../../assets/img/ScreenshotThemeSwitch.mp4";

const Home = () => {
    const [visiblePopup, setVisiblePopup] = useState(null);
    const {isAuthenticated, logout} = useContext(AuthContext);

    return (
        <div className="home">
            <CustomScroll>
                <div className="home__wrapper">
                    <AuthModal visiblePopup={visiblePopup}
                               setVisiblePopup={setVisiblePopup}
                    />
                    <header className="header">
                        <div className="container">
                            <div className="header__wrapper">
                                <div className="header__logo">
                                    <a href="/">
                                        <img src={logo} alt="Logo"/>
                                    </a>
                                </div>
                                <div className="header__button">
                                    {isAuthenticated ? (
                                        <div>
                                            <MyButton
                                                onClick={() =>
                                                    logout()
                                                }
                                            >Logout</MyButton>
                                        </div>
                                    ) : (
                                        <div>
                                            <MyButton
                                                onClick={() =>
                                                    setVisiblePopup('login')
                                                }
                                            >Login</MyButton>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </header>

                    <section className="intro">
                        <div className="container">
                            <div className="intro__wrapper">
                                <h1>Your daily life organizer</h1>
                                <h2>
                                    Stay on track, achieve success - with our
                                    ultimate todo app!
                                </h2>
                                {isAuthenticated ? (
                                    <Link to="/lists/all">
                                        <MyButton>Enter app</MyButton>
                                    </Link>
                                ) : (
                                    <div>
                                        <MyButton
                                            onClick={() =>
                                                setVisiblePopup('login')
                                            }
                                        >Login</MyButton>
                                    </div>
                                )}
                            </div>
                        </div>
                    </section>

                    <section className="images">
                        <div className="images__block">
                            <img src={img1} alt="Task lists"/>
                            <p>
                                You can create any number of lists on any topic
                                you want!
                                <br/>
                                Sort your tasks. Divide big goals into smaller
                                ones.
                            </p>
                        </div>

                        <div className="images__block">
                            <p>
                                Track the progress of tasks and brush aside
                                completed tasks.
                                <br/>
                                Easily add new tasks with one click.
                            </p>
                            <img src={img2} alt="Tasks"/>
                        </div>

                        <div className="images__block">
                            <img src={img3} alt="All lists"/>
                            <p>
                                View a list of all your tasks at once in one
                                window.
                                <br/>
                                Analyze your todo lists and set yourself goals.
                            </p>
                        </div>

                        <div className="images__block">
                            <p>
                                Do you work at night or just love dark colors?
                                <br/>
                                Choose Night Mode. No complex settings, just one
                                click.
                            </p>
                            <video loop autoPlay muted>
                                <source type="video/mp4" src={img4}></source>
                            </video>
                        </div>
                    </section>
                    <footer className="footer">
                        <div className="container">
                            <div className="footer__wrapper">
                                <div className="footer__logo">
                                    <img src={logo} alt=""/>
                                </div>
                                <div className="footer__copyright">
                                    <span>
                                        &copy; 2023 Your daily life organizer
                                    </span>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </CustomScroll>
        </div>
    );
};

export default Home;
