import React from 'react';
import {CheckIcon} from "@heroicons/react/24/outline";

import './Checkbox.scss';

const Checkbox = ({checked, onChange, id = "checkbox_1", label = ''}) => {
  return (
          <div className="checkbox">
            <input
                    id={id}
                    type="checkbox"
                    checked={checked}
                    onChange={onChange}
            />
            <label className="checkbox-icon" htmlFor={id}>
              <CheckIcon className="check-icon"/>
            </label>
            {label && <label htmlFor={id}>{label}</label>}
          </div>
  );
};

export default Checkbox;