import React, {useContext, useEffect, useState} from "react";
import classNames from "classnames";
import {useLocation, useNavigate} from "react-router-dom";
import {PlusIcon, XCircleIcon} from "@heroicons/react/24/outline";
import axios from "axios";

import {List, MyButton, Badge, MyInputGroup, Checkbox} from "../";
import {API_URL, tables, actions} from "../../api";
import {AuthContext, TodoContext} from "../../contexts";

import "./AddList.scss";

const AddList = () => {
  const {isAdmin} = useContext(AuthContext);
  const {colors, addList} = useContext(TodoContext);
  const [selectedColor, setSelectedColor] = useState(1);
  const [inputValues, setInputValues] = useState({name: '', shared: false});
  const [visiblePopup, setVisiblePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputAlert, setInputAlert] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (colors[0]) {
      setSelectedColor(colors[0].id);
    }
  }, [colors]);

  const onAddList = () => {
    if (!inputValues.name.trim()) {
      setInputAlert("Введите название списка!");
      return;
    }

    setIsLoading(true);
    axios
            .post(API_URL, {
              table: tables.lists,
              action: actions.add,
              name: inputValues.name.trim(),
              colorId: selectedColor,
              shared: inputValues.shared,
            })
            .then(({data}) => {
              if (!data['error']) {
                const color = colors.find(color => color.id === selectedColor);
                const newList = {
                  ...data,
                  color: {
                    name: color.name,
                    hash: color.hash
                  },
                  tasks: [],
                  shared: data['userId'] === 0
                };
                addList(newList);
                navigate(`/lists/${newList.id}`);
              } else {
                alert(data['error']);
              }
            })
            .catch(() => {
              alert("Ошибка добавления списка!");
            })
            .finally(() => {
              onClose();
              setIsLoading(false);
            });

    setSelectedColor(colors[0].id);
    setInputValues({name: '', shared: false});
  }

  const onClose = () => {
    setVisiblePopup(false);
    setInputValues({name: '', shared: false});
    setInputAlert('');
    setSelectedColor(colors[0].id);
  };

  return (
          <div className="add-list">
            <List
                    onClick={() => {
                      setVisiblePopup(!visiblePopup);
                    }}
                    items={[
                      {
                        path: location.pathname,
                        className: "add-list-button",
                        icon: <PlusIcon/>,
                        name: "Добавить список"
                      }
                    ]}
            />
            {visiblePopup && (
                    <div className="add-list__popup">
                      <i><XCircleIcon fill="white" className="add-list__popup-close-button" onClick={onClose}/></i>

                      <ul className="add-list__popup-color-picker">
                        {colors.map((color) =>
                                <Badge
                                        onClick={() => {
                                          setSelectedColor(color.id);
                                        }}
                                        color={color.hash}
                                        className={classNames({"active": selectedColor === color.id})}
                                        key={color.id}
                                />
                        )}
                      </ul>

                      <MyInputGroup>
                        <input
                                autoFocus
                                className={classNames("scale", {"alert": inputAlert})}
                                type="text"
                                placeholder="Название списка"
                                value={inputValues.name}
                                onChange={e => {
                                  setInputValues({...inputValues, name: e.target.value});
                                  setInputAlert("");
                                }}
                                onKeyDown={e => {
                                  if (e.code === "Enter") {
                                    e.preventDefault();
                                    document.getElementById("addListBtn").click();
                                  }
                                }}
                                required
                        />
                      </MyInputGroup>
                      {isAdmin &&
                              <Checkbox
                                      checked={inputValues.shared}
                                      onChange={() =>
                                              setInputValues({...inputValues, shared: !inputValues.shared})}
                                      label="Общий список"
                                      id="shared"
                              />
                      }
                      {inputAlert && <small>{inputAlert}</small>}

                      <MyButton id="addListBtn" onClick={onAddList}>
                        {isLoading ? "Добавление..." : "Добавить"}
                      </MyButton>
                    </div>
            )}
          </div>
  );
};

export default AddList;
