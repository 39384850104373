import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from '../../../contexts';
import {AdmContext} from '../../../contexts/adm';
import {useLocation} from "react-router-dom";
import {ArrowLeftOnRectangleIcon} from "@heroicons/react/24/outline";
import Projects from "../Projects";

const AdmContent = () => {
  const {logout, username} = useContext(AuthContext);
  const location = useLocation();
  const {admTables} = useContext(AdmContext);
  const [activeTable, setActiveTable] = useState({});

  useEffect(() => {
    if (admTables) {
      const tableCode = location.pathname.split('adm/')[1].split('/')[0]
      setActiveTable(admTables.find(t => t.code === tableCode));
    }
  }, [location.pathname, admTables]);

  const getModule = (label) => {
    switch (label) {
      case 'projects':
        return <Projects table={activeTable}/>
      case 'lists':
        return <h2>LISTS</h2>
      default:
        return false;
    }
  }

  return (
          activeTable &&
          <>
            <div className="logout">
              <div className="logout__username">{username}</div>
              <div className="logout__button" onClick={logout}>
                <ArrowLeftOnRectangleIcon/>
              </div>
            </div>
            <h1>{activeTable.name}</h1>
            {getModule(activeTable.label)}
          </>
  );
};

export default AdmContent;