export const API_URL = `https://${window.location.hostname}/api/api.php`;
export const tables = {
  lists: 'lists',
  colors: 'colors',
  tasks: 'tasks',
  users: 'users',
  taskCompletion: 'task_completion',
  projects: 'projects',
};
export const actions = {
  getAdmTables: 'get_adm_tables',
  getUser: 'get_user',
  getProjects: 'get_projects',
  logout: 'logout',
  add: 'add',
  edit: 'edit',
  delete: 'delete',
  check: 'check',
  removeCompleted: 'remove_completed',
  login: 'login',
  register: 'register',
  changeStatus: 'change_status',
};