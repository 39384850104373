import React, {useContext} from 'react';
import classNames from "classnames";
import axios from "axios";

import {MyButton, MyInputGroup} from "../index";
import {actions, API_URL, tables} from "../../api";
import {AuthContext} from "../../contexts";

const RegisterForm = ({setVisiblePopup, inputValues, setInputValues, inputAlert, setInputAlert, setSuccessAlert}) => {
    const {register} = useContext(AuthContext);

    const submitHandler = (e) => {
        e.preventDefault();
        if (
            inputValues.login === ""
            || inputValues.mail === ""
            || inputValues.password === ""
            || inputValues.password2 === ""
        ) {
            setInputAlert(['Заполните все поля']);
        } else if (inputValues.password !== inputValues.password2) {
            setInputAlert(['Пароли не совпадают']);
        } else {
            register(
                inputValues.login,
                inputValues.password,
                inputValues.password2,
                inputValues.mail,
                (data) => {
                    if (data['error']) {
                        setInputAlert(data['error']);
                    } else {
                        setVisiblePopup('login');
                        setSuccessAlert('Вы успешно зарегистрировались!');
                    }
                });
        }
    }

    return (
        <>
            <form id="register-form" onSubmit={submitHandler}>
                <MyInputGroup>
                    <label htmlFor="login">Имя пользователя</label>
                    <input
                        className={classNames({"alert": inputAlert})}
                        type="text"
                        id="login"
                        value={inputValues.login ?? ""}
                        onChange={e => {
                            setInputValues({...inputValues, login: e.target.value});
                            setInputAlert("");
                        }}
                        autoFocus
                    />
                </MyInputGroup>
                <MyInputGroup>
                    <label htmlFor="mail">Эл. почта</label>
                    <input
                        className={classNames({"alert": inputAlert})}
                        type="email"
                        id="mail"
                        value={inputValues.mail}
                        onChange={e => {
                            setInputValues({...inputValues, mail: e.target.value});
                            setInputAlert("");
                        }}
                    />
                </MyInputGroup>
                <MyInputGroup>
                    <label htmlFor="password">Пароль</label>
                    <input
                        className={classNames({"alert": inputAlert})}
                        type="password"
                        id="password"
                        value={inputValues.password}
                        onChange={e =>
                            setInputValues({...inputValues, password: e.target.value})
                        }
                    />
                </MyInputGroup>
                <MyInputGroup>
                    <label htmlFor="password2">Подтвердите пароль</label>
                    <input
                        className={classNames({"alert": inputAlert})}
                        type="password"
                        id="password2"
                        value={inputValues.password2}
                        onChange={e =>
                            setInputValues({...inputValues, password2: e.target.value})
                        }
                    />
                </MyInputGroup>
                {inputAlert && <small dangerouslySetInnerHTML={{__html: inputAlert.join('<br>')}}/>}
                <MyButton type="submit">Sign Up</MyButton>
            </form>
            <div className="subtext">
                <span>Уже есть аккаунт?
                    <a href="/login"
                       onClick={(e) => {
                           e.preventDefault();
                           setVisiblePopup('login');
                       }}
                    >Войдите!</a>
                </span>
            </div>
        </>
    );
};

export default RegisterForm;