import React, {createContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import axios from "axios";

import {API_URL, actions, tables} from "../api";

// Создаем контекст авторизации
export const AuthContext = createContext(null);

// Создаем провайдер контекста
export const AuthProvider = ({children}) => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [username, setUsername] = useState('');
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    getUser();
    getProjects();
  }, []);

  const getUser = () => {
    axios
            .post(API_URL, {
              action: actions.getUser
            })
            .then(({data}) => {
              if (data['user_id']) {
                setIsAuthenticated(true);
                setIsAdmin(data['admin']);
                setUsername(data['username']);
              } else {
                setIsAuthenticated(false);
                setIsAdmin(false);
                setUsername('');
              }
            })
            .catch(() => {
              alert('Server error! get_user at AuthContext');
            });
  }

  const getProjects = () => {
    axios
            .post(API_URL, {
              action: actions.getProjects
            })
            .then(({data}) => {
              if (!data['error']) {
                setProjects(data['projects']);
              } else {
                alert(data['error']);
              }
            })
            .catch(() => {
              alert('Server error! get_projects at AuthContext');
            });
  }

  // Функция для входа пользователя
  const login = (login, pass, then) => {
    axios
            .post(API_URL, {
              table: tables.users,
              action: actions.login,
              login,
              pass
            })
            .then(({data}) => {
              if (!data['error']) {
                setIsAuthenticated(true);
                setIsAdmin(data['admin']);
                setUsername(data['username']);
                // if (data['admin']) {
                //   getAdmTables();
                // }
              }
              then(data);
            })
            .catch(() => {
              alert('Server error! login at AuthContext');
            });
  };

  const register = (login, pass, pass2, mail, then) => {
    axios
            .post(API_URL, {
              table: tables.users,
              action: actions.register,
              login,
              pass,
              pass2,
              mail
            })
            .then(({data}) => {
              then(data);
            })
  };
  // Функция для выхода пользователя
  const logout = () => {
    axios
            .post(API_URL, {
              action: actions.logout
            })
            .then(() => {
              setIsAuthenticated(false);
              setIsAdmin(false);
              setUsername('');
              setTimeout(() => navigate('/'), 2);
            })
            .catch(() => {
              alert('Server error! logout at AuthContext')
            })
  };

  // Значения контекста, доступные для дочерних компонентов
  const authContextValues = {
    isAuthenticated,
    isAdmin,
    username,
    projects,
    getProjects,
    login,
    register,
    logout,
  };

  return (
          <AuthContext.Provider value={authContextValues}>
            {children}
          </AuthContext.Provider>
  );
};
