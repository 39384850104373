import React, {useEffect, useState} from 'react';
import {XCircleIcon} from "@heroicons/react/24/outline";

import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";

import './AuthModal.scss';

const AuthModal = ({visiblePopup, setVisiblePopup}) => {
    const [inputValues, setInputValues] = useState({
        login: "",
        password: "",
        password2: "",
        mail: ""
    });
    const [inputAlert, setInputAlert] = useState('');
    const [successAlert, setSuccessAlert] = useState('');

    useEffect(() => {
        setInputValues({login: "", password: "", password2: "", mail: ""});
        setInputAlert('');
    }, [visiblePopup])

    const onClose = () => {
        setVisiblePopup(null);
        setSuccessAlert('');
    };

    return (<>
        {visiblePopup && (
            <div className="auth-popup" onClick={onClose}>
                <div className="auth-popup__wrapper"
                     onClick={e => e.stopPropagation()}>
                    <i><XCircleIcon fill="white" className="close-button"
                                    onClick={onClose}/></i>
                    <div className="success-alert">{successAlert}</div>
                    {visiblePopup === 'login'
                        ?
                        <LoginForm
                            setVisiblePopup={setVisiblePopup}
                            inputValues={inputValues}
                            setInputValues={setInputValues}
                            inputAlert={inputAlert}
                            setInputAlert={setInputAlert}
                            onClose={onClose}
                        />
                        :
                        <RegisterForm
                            setVisiblePopup={setVisiblePopup}
                            inputValues={inputValues}
                            setInputValues={setInputValues}
                            inputAlert={inputAlert}
                            setInputAlert={setInputAlert}
                            setSuccessAlert={setSuccessAlert}
                        />
                    }
                </div>
            </div>
        )}
    </>);

};

export default AuthModal;