import React, {useContext} from 'react';
import {MyButton, MyInputGroup} from "../index";
import classNames from "classnames";
import {useNavigate} from "react-router-dom";

import {AuthContext} from '../../contexts';

const LoginForm = ({setVisiblePopup, inputValues, setInputValues, inputAlert, setInputAlert, onClose}) => {
    const navigate = useNavigate();
    const {login} = useContext(AuthContext);

    const submitHandler = (e) => {
        e.preventDefault();
        if (inputValues.login === "" || inputValues.password === "") {
            setInputAlert(['Заполните все поля']);
        } else {
            login(
                inputValues.login,
                inputValues.password,
                (data) => {
                    if (data['error']) {
                        setInputAlert(data['error']);
                    } else {
                        onClose();
                        setTimeout(() => navigate('/lists/all'), 1);
                    }
                }
            );
        }
    }

    return (
        <>
            <form id="login-form" onSubmit={submitHandler}>
                <MyInputGroup>
                    <label htmlFor="login">Эл. почта</label>
                    <input
                        className={classNames({"alert": inputAlert})}
                        type="email"
                        id="login"
                        value={inputValues.login}
                        onChange={e => {
                            setInputValues({...inputValues, login: e.target.value});
                            setInputAlert("");
                        }}
                        autoFocus
                    />
                </MyInputGroup>
                <MyInputGroup>
                    <label htmlFor="password">Пароль</label>
                    <input
                        className={classNames({"alert": inputAlert})}
                        type="password"
                        id="password"
                        value={inputValues.password}
                        onChange={e =>
                            setInputValues({...inputValues, password: e.target.value})
                        }
                    />
                </MyInputGroup>
                {inputAlert && <small dangerouslySetInnerHTML={{__html: inputAlert.join('<br>')}}/>}
                <MyButton type="submit">Войти</MyButton>
            </form>
            <div className="subtext">
                <span>Ещё нет аккаунта?
                    <a href="/register"
                       onClick={(e) => {
                           e.preventDefault();
                           setVisiblePopup('register');
                       }}
                    >Зарегистрируйтесь!</a>
                </span>
            </div>
        </>
    );
};

export default LoginForm;