import React, {useContext} from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import {ArrowLeftOnRectangleIcon} from "@heroicons/react/24/outline";

import AddTaskForm from "./AddTaskForm";
import TaskItem from "./TaskItem";

import "./Tasks.scss";
import {AuthContext, TodoContext} from "../../contexts";

const Tasks = () => {
    const {logout, isAdmin, username} = useContext(AuthContext);
    const {lists, activeList, editListTitle, removeCompleted} = useContext(TodoContext);
    const activeLists = activeList ? [activeList] : lists;
    const completedCount = activeList ? activeList.tasks.filter(task => task.completed === true).length : 0;

    return (
        <div className="tasks">
            <div className="logout">
                <div className="logout__username">{username}</div>
                <div className="logout__button" onClick={logout}>
                    <ArrowLeftOnRectangleIcon/>
                </div>
            </div>
            {
                lists && lists.length === 0
                    ? (<h3>Нет списков</h3>)
                    : (activeLists && activeLists.map(list =>
                        <div className="tasks__list" key={list.id}>
                            <h2 className={`tasks__title`}
                                style={{color: list.color.hash}}>
                                <TextareaAutosize
                                    disabled={list.shared && !isAdmin}
                                    spellCheck={false}
                                    onBlur={e =>
                                        editListTitle(list, e.target.value)
                                    }
                                    rows={1}
                                >{list.name}</TextareaAutosize>
                            </h2>

                            {completedCount > 0 &&
                                <div className="tasks__remove-completed">
                                    <small onClick={removeCompleted}>Убрать выполненные</small>
                                </div>
                            }

                            <div className="tasks__items">
                                {!list.tasks.length
                                    ? <h3>Нет задач</h3>
                                    : list.tasks.map((task) => (
                                        <TaskItem
                                            key={task.id}
                                            sharedList={list.shared}
                                            task={task}/>
                                    ))
                                }
                                {(!list.shared || isAdmin) &&
                                    <AddTaskForm key={list.id} listId={list.id}/>
                                }
                            </div>
                        </div>
                    ))}
        </div>
    );
};

export default Tasks;