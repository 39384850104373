import React, {useContext, useState} from 'react';
import {CheckIcon, TrashIcon} from "@heroicons/react/24/solid";

import {AuthContext, TodoContext} from "../../contexts";

const TaskItem = ({sharedList, task}) => {
          const {isAdmin} = useContext(AuthContext);
          const {editTask, removeTask, changeCompleted} = useContext(TodoContext);
          const [inputValue, setInputValue] = useState(task.text);

          return (
                  <div className="tasks__items-row">

                    <div className="checkbox">
                      <input
                              id={`task-${task.id}`}
                              type="checkbox"
                              checked={task.completed}
                              onChange={(e) =>
                                      changeCompleted(task.listId, task.id, e.target.checked, sharedList)
                              }
                      />
                      <label htmlFor={`task-${task.id}`}>
                        <CheckIcon className="check-icon"/>
                      </label>
                    </div>

                    {sharedList && !isAdmin ?
                            (<label htmlFor={`task-${task.id}`} className="task-text">
                              {task.text}
                            </label>)
                            :
                            (<input
                                    className="task-text"
                                    type="text"
                                    value={inputValue.toString()}
                                    onChange={e => setInputValue(e.target.value)}
                                    onKeyDown={e => {
                                      if (e.code === "Enter") {
                                        e.preventDefault();
                                        editTask(task, inputValue, sharedList);
                                      }
                                    }}
                                    onBlur={e => {
                                      editTask(task, inputValue, sharedList);
                                    }}
                            />)}
                    {(!sharedList || isAdmin) &&
                            <TrashIcon className="task-delete-icon"
                                       onClick={() => removeTask(task.listId, task.id, sharedList)}/>
                    }
                  </div>
          );
        }
;

export default TaskItem;