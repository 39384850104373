import React, {useContext, useEffect, useState} from 'react';

import './Projects.scss';
import {useLocation} from "react-router-dom";
import {AuthContext} from "../../../contexts";
import List from "./List";
import Edit from "./Edit";

const Projects = ({table}) => {
  const location = useLocation();
  const {projects} = useContext(AuthContext);
  const [activeProject, setActiveProject] = useState({});

  useEffect(() => {
    if (projects) {
      if (location.pathname.split(`adm/${table.code}/`)[1]) {
        const projectCode = location.pathname.split(`adm/${table.code}/`)[1].split('/')[0];
        setActiveProject(projects.find(p => p.code === projectCode));
      } else {
        setActiveProject({});
      }
    }
  }, [location.pathname]);

  return (
          <div className='projects'>
            {activeProject.id > 0 ? (
                    <Edit project={activeProject}/>
            ) : (
                    <List projects={projects}/>
            )}
          </div>
  );
};

export default Projects;

/*
  <tr>
    <th align='center'>ID</th>
    <th align='left'>Название</th>
    <th align='left'>Код</th>
    <th align='left'>Цвет</th>
    <th align='center'>Статус</th>
    <th align='right'>Действия</th>
  </tr>
  */