import React, {useContext} from "react";
import {Outlet} from "react-router-dom";
import {ListBulletIcon} from "@heroicons/react/24/solid";
import CustomScroll from "react-custom-scroll";
import "react-custom-scroll/dist/customScroll.css";

import {AddList, List, ThemeSwitch} from "./";
import {TodoContext} from "../contexts";
import ProjectSelect from "./ProjectSelect";

const Layout = () => {
  const {lists, setLists} = useContext(TodoContext);

  return (
          <>
            <div className="App">
              <div className="todo">
                <CustomScroll className="sidebar-container">
                  <aside className="todo__sidebar">
                    {/* Project select */}
                    <ProjectSelect/>

                    {/* All tasks button */}
                    <List items={[
                      {
                        path: "/lists/all",
                        active: true,
                        name: "Все списки",
                        icon: <ListBulletIcon/>,
                        shared: false
                      }
                    ]}/>

                    {/* Task lists */}
                    <List
                            isRemovable
                            onRemove={id => {
                              const newLists = lists.filter(list => list.id !== id);
                              setLists(newLists);
                            }}
                            items={lists}
                            className={"todo__lists-item"}
                    />

                    <AddList/>
                  </aside>

                </CustomScroll>
                <CustomScroll className="tasks-container flex-1">
                  <div className="todo__tasks">
                    <Outlet/>
                  </div>
                </CustomScroll>

                <ThemeSwitch/>
              </div>
            </div>
          </>
  );
};

export default Layout;