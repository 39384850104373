import React, {useContext} from 'react';
import {Outlet} from "react-router-dom";
import Menu from "./Menu";
import CustomScroll from "react-custom-scroll";
import {ThemeSwitch} from "../index";
import {AdmContext, AdmProvider} from "../../contexts/adm";

const AdminPanel = () => {
  const {admTables} = useContext(AdmContext);

  return (
          <AdmProvider>
            {admTables &&
                    <div className="App">
                      <div className="todo adm">
                        <Menu/>
                        <CustomScroll className="tasks-container flex-1">
                          <div className="todo__tasks">
                            <Outlet/>
                          </div>
                        </CustomScroll>

                        <ThemeSwitch/>
                      </div>
                    </div>
            }
          </AdmProvider>
  );
};

export default AdminPanel;