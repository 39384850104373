import React, {createContext, useEffect, useState} from 'react';
import axios from "axios";

import {API_URL, actions, tables} from "../../api";

export const AdmContext = createContext(null);

// Создаем провайдер контекста
export const AdmProvider = ({children}) => {
  const [admTables, setAdmTables] = useState([]);

  useEffect(() => {
    getAdmTables();
  }, []);

  // Получение разделов в админке
  const getAdmTables = () => {
    axios
            .post(API_URL, {
              action: actions.getAdmTables
            })
            .then(({data}) => {
              if (!data['error']) {
                setAdmTables(data['adm_tables']);
              } else {
                alert(data['error']);
              }
            })
            .catch(() => {
              alert('Server error! getAdmTables at AdmContext');
            });
  };

  // Значения контекста, доступные для дочерних компонентов
  const admContextValues = {
    admTables,
  };

  return (
          <AdmContext.Provider value={admContextValues}>
            {children}
          </AdmContext.Provider>
  );
};
