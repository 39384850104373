import React, {createContext, useContext, useEffect, useState} from 'react';
import axios from "axios";

import {actions, API_URL, tables} from "../api";
import {AuthContext} from "./AuthContext";

// Создаем контекст
export const TodoContext = createContext(null);

// Создаем провайдер контекста
export const TodoProvider = ({children}) => {
  const {isAuthenticated, isAdmin} = useContext(AuthContext);
  const [lists, setLists] = useState(null);
  const [colors, setColors] = useState(null);
  const [activeList, setActiveList] = useState(null);

  useEffect(() => {
    // берем списки и цвета и записываем с состояния
    axios
            .get(`${API_URL}?table=${tables.lists}`)
            .then(({data}) => {
              setLists(data);
            })
            .catch(error => {
              console.error(error);
            });
    axios.get(`${API_URL}?table=${tables.colors}`)
            .then(({data}) => {
              setColors(data);
            });
  }, [isAuthenticated])

  const addList = (newList) => {
    setLists([...lists, newList]);
  }

  const editListTitle = (list, newTitle) => {
    if (list.shared && !isAdmin) return;
    if (newTitle !== list.name) {
      axios
              .post(API_URL, {
                table: tables.lists,
                action: actions.edit,
                id: list.id,
                name: newTitle
              })
              .then(({data}) => {
                if (!data['error']) {
                  const newLists = lists.map((l) => {
                    if (l.id === list.id) {
                      l.name = newTitle;
                    }
                    return l;
                  });
                  setLists(newLists);
                } else {
                  alert(data['error']);
                }
              })
              .catch(() =>
                      alert("Failed to change list name!")
              );
    }

  };

  const addTask = (listId, newTaskObj) => {
    const newLists = lists.map((list) => {
      if (list.id === listId) {
        list.tasks = [...list.tasks, newTaskObj];
      }
      return list;
    });
    setLists(newLists);
  };

  const editTask = (task, text, sharedList) => {
    if (sharedList && !isAdmin) return;

    if (text.trim() === "") {
      removeTask(task.listId, task.id, sharedList);
      return;
    }

    if (text !== task.text) {
      axios
              .post(API_URL, {
                table: tables.tasks,
                action: actions.edit,
                id: task.id,
                text
              })
              .then(({data}) => {
                if (!data['error']) {
                  const newLists = lists.map((list) => {
                    if (list.id === task.listId) {
                      list.tasks = list.tasks.map((t) => {
                        if (t.id === task.id) {
                          t = {
                            ...t,
                            text,
                          };
                        }
                        return t;
                      });
                    }
                    return list;
                  });
                  setLists(newLists);
                } else {
                  alert(data['error']);
                }
              })
              .catch(() => {
                alert("Failed to edit task!");
              });
    }
  };

  const removeTask = (listId, taskId, sharedList) => {
    if (sharedList && !isAdmin) return;
    axios
            .post(API_URL, {
              table: tables.tasks,
              action: actions.delete,
              id: taskId
            })
            .then(({data}) => {
              if (!data['error']) {
                const newLists = lists.map((list) => {
                  if (list.id === listId) {
                    list.tasks = list.tasks.filter((task) => task.id !== taskId);
                  }
                  return list;
                });
                setLists(newLists);
              } else {
                alert(data['error']);
              }
            })
            .catch(() =>
                    alert("Failed to delete tasks!")
            );
  };

  const changeCompleted = (listId, taskId, completed, sharedList) => {
    const table = (sharedList && !isAdmin) ? tables.taskCompletion : tables.tasks;
    axios
            .post(API_URL, {
              table,
              action: actions.check,
              id: taskId,
              completed: completed
            })
            .then(({data}) => {
              if (!data['error']) {
                const newLists = lists.map((list) => {
                  if (list.id === listId) {
                    list.tasks = list.tasks.map((task) => {
                      if (task.id === taskId) {
                        task = {
                          ...task,
                          completed,
                        };
                      }
                      return task;
                    });
                  }
                  return list;
                });
                setLists(newLists);
              } else {
                alert(data['error']);
              }
            })
            .catch(() => {
              alert("Failed to edit task!");
            });
  };

  const removeCompleted = () => {
    if (activeList) {
      const table = (activeList.shared && !isAdmin) ? tables.taskCompletion : tables.tasks;

      axios
              .post(API_URL, {
                table,
                action: actions.removeCompleted,
                listId: activeList.id
              })
              .then(({data}) => {
                if (!data['error']) {
                  const newLists = lists.map((list) => {
                    if (list.id === activeList.id) {
                      if (list.shared && !isAdmin) {
                        list.tasks = list.tasks.map((task) => {
                          return {
                            ...task,
                            completed: false
                          };
                        })
                      } else {
                        list.tasks = list.tasks.filter(
                                (task) => task.completed === false
                        );
                      }
                    }
                    return list;
                  });
                  setLists(newLists);
                } else {
                  alert(data['error']);
                }
              })
              .catch(() =>
                      alert("Failed to delete tasks!")
              );
    }

  };

  // Значения контекста, доступные для дочерних компонентов
  const listsContextValues = {
    lists,
    colors,
    setLists,
    activeList,
    setActiveList,
    addList,
    editListTitle,
    addTask,
    editTask,
    removeTask,
    changeCompleted,
    removeCompleted
  };

  return (
          <TodoContext.Provider value={listsContextValues}>
            {children}
          </TodoContext.Provider>
  );
};
