import React, {useContext, useEffect, useState} from 'react';
import {ChevronDownIcon} from "@heroicons/react/24/outline";
import Select from 'react-select';

import {AuthContext} from "../../contexts";
import {List} from "../"

import "./ProjectSelect.scss";
import {useNavigate} from "react-router-dom";

const ProjectSelect = () => {
  const navigate = useNavigate();
  const {projects} = useContext(AuthContext);
  const [selectedProj, setSelectedProj] = useState(null);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const storageProj = localStorage.getItem("selectedProject");

    if (storageProj) {
      const newSelectedProj = projects.find(p => p.code === storageProj);
      setSelectedProj({value: newSelectedProj.code, label: newSelectedProj.name});
    } else {
      if (projects && projects.length > 0) {
        setSelectedProj({value: projects[0].code, label: projects[0].name});
      }
    }

    if (options && options.length < 1) {
      if (projects) {
        let newOpts = projects.map((p) => {
          return {
            value: p.code,
            label: p.name,
          }
        })
        setOptions(newOpts);
      }
    }
  }, [projects])

  const handleProjectChange = (selectedOption) => {
    setSelectedProj(selectedOption);
  };

  useEffect(() => {
    if (selectedProj) {
      localStorage.setItem("selectedProject", selectedProj.value);
    }
  }, [selectedProj])

  return (
          <div className="projects-select">
            {selectedProj &&
                    <Select
                            className="custom-select"
                            defaultValue={selectedProj}
                            onChange={handleProjectChange}
                            options={options}
                            styles={{
                              control: base => ({
                                ...base,
                                border: 0,
                                // This line disable the blue border
                                boxShadow: 'none',
                                background: 'transparent',
                              })
                            }}
                    />
            }
            {/*<List*/}
            {/*        onClick={toggleShowList}*/}
            {/*        items={[*/}
            {/*          {*/}
            {/*            path: "",*/}
            {/*            active: true,*/}
            {/*            name: 'proj',*/}
            {/*            icon: <ChevronDownIcon/>,*/}
            {/*            shared: false,*/}
            {/*          }*/}
            {/*        ]}/>*/}
            {/*{showList && projects &&*/}
            {/*        <ul className="projects-select__list">*/}
            {/*          {projects.map((project) => (*/}
            {/*                  <li className="projects-select__list-item"*/}
            {/*                      onClick={() => onChangeProject(project)}*/}
            {/*                      data-value={project.code}>*/}
            {/*                    {project.name}*/}
            {/*                  </li>*/}
            {/*          ))}*/}
            {/*        </ul>*/}
            {/*}*/}
          </div>
  );
};

export default ProjectSelect;