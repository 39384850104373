import React, {useContext, useState} from 'react';
import {PlusIcon} from "@heroicons/react/24/outline";
import axios from "axios";

import {API_URL, tables, actions} from "../../api";
import {TodoContext} from "../../contexts";

const AddTaskForm = ({listId}) => {
    const {addTask} = useContext(TodoContext);
    const [inputValue, setInputValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const onAdd = () => {
        if (inputValue.trim()) {
            setIsLoading(true);
            axios
                .post(API_URL, {
                    table: tables.tasks,
                    action: actions.add,
                    listId: listId,
                    text: inputValue.trim()
                })
                .then(({data}) => {
                    if (!data['error']) {
                        data.completed = data.completed === 1;
                        addTask(listId, data);
                        setInputValue("");
                    } else {
                        alert(data['error']);
                    }
                })
                .catch(() => {
                    alert("Ошибка добавления задачи!");
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }
    }

    return (
        <div className="tasks__items-row">
            <PlusIcon className="plus-icon"/>
            <input
                className="task-text new"
                type="text"
                value={inputValue}
                placeholder="Введите новую задачу..."
                onKeyDown={e => {
                    if (e.code === "Enter") {
                        e.preventDefault();
                        onAdd();
                    }
                }}
                onBlur={() => onAdd()}
                onChange={e => setInputValue(e.target.value)}
                disabled={isLoading}
            />
        </div>
    );
};

export default AddTaskForm;