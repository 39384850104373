import React, {useEffect, createContext, useState} from "react";

const defaultTheme = "light";
export const ThemeContext = createContext(defaultTheme);

// Function to get the theme from local storage or use the default theme
const getTheme = () => {
    const theme = localStorage.getItem("theme");
    if (!theme) {
        localStorage.setItem("theme", defaultTheme);
        return defaultTheme;
    } else {
        return theme;
    }
};

export const ThemeProvider = ({children}) => {
    const [theme, setTheme] = useState(getTheme);

    const toggleTheme = () => {
        if (theme === "dark") {
            setTheme("light");
        } else {
            setTheme("dark");
        }
    }

    // Update the local storage when the theme changes
    useEffect(() => {
        localStorage.setItem("theme", theme.toString());
        // Update the theme class on the document element
        if (theme === "dark") {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    }, [theme]);

    // Значения контекста, доступные для дочерних компонентов
    const themeContextValues = {
        theme,
        setTheme,
        toggleTheme,
    }

    return (
        <ThemeContext.Provider value={themeContextValues}>
            {children}
        </ThemeContext.Provider>
    );
}