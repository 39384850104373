import React, {useContext, useEffect} from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import {ArrowPathIcon} from "@heroicons/react/24/outline";

import {Tasks, NotFound, Layout, Home} from "./components";
import {AuthContext, TodoContext} from "./contexts";
import AdminPanel from "./components/AdminPanel";
import AdmContent from "./components/AdminPanel/AdmContent";

function App() {
  const location = useLocation();
  const {isAuthenticated, isAdmin} = useContext(AuthContext);
  const {lists, setActiveList, colors} = useContext(TodoContext);

  useEffect(() => {
    if (lists) {
      // Extract the active list based on the current route
      const listId = location.pathname.split("lists/")[1];
      const newActiveList = lists.find((list) => list.id === listId);
      setActiveList(newActiveList);
    }
  }, [lists, location.pathname]);

  return <>
    {lists && colors ?
            (
                    <Routes>
                      {isAdmin &&
                              <Route
                                      path="/adm"
                                      element={
                                        <AdminPanel/>
                                      }
                              >
                                <Route
                                        path=":code"
                                        element={
                                          <AdmContent/>
                                        }
                                />
                                <Route
                                        path=":code/:id"
                                        element={
                                          <AdmContent/>
                                        }
                                />
                              </Route>
                      }
                      {isAuthenticated && <Route
                              path="/lists"
                              element={
                                <Layout/>
                              }
                      >
                        <Route
                                path=":id"
                                element={
                                  <Tasks/>
                                }
                        />
                        <Route
                                path="all"
                                element={
                                  <Tasks/>
                                }
                        />
                      </Route>}
                      <Route path="/" element={<Home/>}/>
                      <Route path="*" element={<NotFound/>}/>
                    </Routes>
            )
            :
            (<div className="loading">
              <ArrowPathIcon className="loading-icon"/>
              <div className="loading-text">Загрузка...</div>
            </div>)
    }
  </>
}

export default App;
