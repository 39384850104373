import React, {useState} from 'react';
import classNames from "classnames";
import {MyButton, MyInputGroup, Checkbox} from "../../";

const Edit = ({project}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [inputAlert, setInputAlert] = useState("");
  const [inputValues, setInputValues] = useState({
    name: project.name,
    code: project.code,
    status: project.status,
  });

  const onEditProject = () => {
    if (!inputValues.name.trim()) {
      setInputAlert("Введите название!");
      return;
    }

    console.log(inputValues);
  }

  return (
          <div className='project'>
            <div className="project__title">{project.name}</div>
            <div className="project__form">
              <form action="">
                <MyInputGroup>
                  <label htmlFor="name">Название</label>
                  <input
                          id="name"
                          className={classNames({"alert": inputAlert})}
                          type="text"
                          placeholder="Введите название"
                          value={inputValues.name}
                          onChange={e => {
                            setInputValues({...inputValues, name: e.target.value});
                            setInputAlert("");
                          }}
                          // onKeyDown={e => {
                          //   if (e.code === "Enter") {
                          //     e.preventDefault();
                          //     document.getElementById("editProjBtn").click();
                          //   }
                          // }}
                          required
                  />
                </MyInputGroup>
                <MyInputGroup>
                  <label htmlFor="code">Код</label>
                  <input
                          id="code"
                          className={classNames({"alert": inputAlert})}
                          type="text"
                          placeholder="Ввведите код"
                          value={inputValues.code}
                          onChange={e => {
                            setInputValues({...inputValues, code: e.target.value});
                            setInputAlert("");
                          }}
                          // onKeyDown={e => {
                          //   if (e.code === "Enter") {
                          //     e.preventDefault();
                          //     document.getElementById("editProjBtn").click();
                          //   }
                          // }}
                          required
                  />
                </MyInputGroup>
                <Checkbox
                        checked={inputValues.status}
                        onChange={() =>
                                setInputValues({...inputValues, status: !inputValues.status})}
                        label="Статус"
                        id="status"
                />
                {inputAlert && <small>{inputAlert}</small>}
                <MyButton id="editProjBtn" onClick={onEditProject}>
                  {isLoading ? "Сохранение..." : "Сохранить"}
                </MyButton>
              </form>
            </div>
          </div>
  );
};

export default Edit;