import React, {useContext, useEffect, useState} from 'react';
import CustomScroll from "react-custom-scroll";
import {List} from "../../";
import {AdmContext} from "../../../contexts/adm";

const Menu = () => {
  const {admTables} = useContext(AdmContext);
  const [items, setItems] = useState(admTables);

  useEffect(() => {
    const newItems = admTables.map(t => {
      return {
        ...t,
        path: t.code
      }
    });
    setItems(newItems);
  }, [admTables])

  return (
          <CustomScroll className="sidebar-container">
            <aside className="todo__sidebar">
              <List
                      items={items}
              />
            </aside>
          </CustomScroll>
  );
};

export default Menu;